body, html, #root, .app {
  width: 100%;
  height: 100%; }

#menu {
  padding: 0 20px 0 20px;
  margin-bottom: 0;
  background: linear-gradient(90deg, #567fdf 0%, #8b99e1 100%);
  border-radius: 0;
  color: white;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(400px, 1fr)); }
  #menu .header {
    font-family: 'Helvetica Neue';
    font-size: 30px;
    align-self: center;
    justify-self: left;
    min-height: 50px;
    padding: 20px 0px;
    grid-template-columns: auto 300px; }
  #menu .options {
    justify-self: right; }
  #menu .item, #menu .header a {
    color: white; }
