.onDemand-outerdiv {
  text-align: -webkit-center;
  padding-bottom: 2em;
}

.max-width {
  max-width: 50%;
  text-align: justify;
  background-color: aliceblue;
  padding: 2%;
  box-shadow: 1px 5px 14px 0px #355557;
  margin-top: 5%;
}

.max-width-history-table {
  max-width: 90%;
}

.ui.button.submit {
  background-color: #a5c3ce;
}
.ui.button.submit:hover {
  background-color: #90bccc;
}

.grid-wrapper {
  text-align: -webkit-center;
  margin: 10em;
}

.detailed-report-accordion {
  text-align: -webkit-center;
  text-align-last: left;
  margin-top: 5em;
}

.summary-card {
  text-align-last: center;
  margin-bottom: 2em;
}

.summary-accordion {
  width: inherit;
  max-width: 80%;
}
.ui.fluid.dropdown {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ui.selection.active.dropdown {
  overflow: visible;
}

.ui.selection.dropdown .menu > .item {
  overflow-wrap: break-word;
}

.detail-table {
  width: 80%;
  margin-top: 3em;
  text-align: initial;
}

.download-screenshot-cell {
  display: inline-flex;
}

.text-align-center {
  text-align-last: center;
}
.ui.input > input {
  width: 80%;
}

.dashboard-header {
  text-align: left;
}

.dashboard-header-right-align {
  text-align: right;
}

.ui.table thead th ~ .lighthouse-table-head {
  width: 100px;
}

.ui.table tr td ~ .text-green {
  color: #21ba45;
}
.ui.table tr td ~ .text-amber {
  color: #f2711c;
}
.ui.table tr td ~ .text-red {
  color: #ff0000;
}
.dashboard-table {
}
